import { j as n } from "./jsx-runtime-B6kdoens.js";
import { _ as d } from "./index-28E3tTLh.js";
import { forwardRef as l, createElement as c } from "react";
import { $ as i } from "./index-CZBInFmv.js";
import { c as u } from "./utils-CJ9afRe1.js";
import { c as m } from "./index-BV2fynjD.js";
const b = /* @__PURE__ */ l((t, r) => /* @__PURE__ */ c(i.label, d({}, t, {
  ref: r,
  onMouseDown: (e) => {
    var a;
    (a = t.onMouseDown) === null || a === void 0 || a.call(t, e), !e.defaultPrevented && e.detail > 1 && e.preventDefault();
  }
}))), p = b, x = m(
  "text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
  {
    variants: {
      truncate: {
        true: "truncate"
      },
      required: {
        true: "truncate"
      },
      variant: {
        default: "text-primary",
        secondary: "text-gray-neutral-70",
        destructive: "text-red-500"
      },
      fontWeight: {
        normal: "font-normal",
        default: "font-medium",
        bold: "font-bold"
      },
      defaultVariants: {
        variant: "default",
        fontWeight: "default",
        truncate: !1
      }
    }
  }
), $ = l(({ className: t, variant: r, fontWeight: e, truncate: a, required: s, ...o }, f) => /* @__PURE__ */ n.jsxs(
  p,
  {
    ref: f,
    className: u(x({ variant: r, truncate: a, fontWeight: e, className: t })),
    ...o,
    children: [
      o.children,
      s && /* @__PURE__ */ n.jsx("span", { className: "text-blue-100", children: "*" })
    ]
  }
));
$.displayName = "Label";
export {
  $ as L
};
