import { a as W, _ as g, c as mt, $ as bt } from "./index-28E3tTLh.js";
import { forwardRef as C, useContext as ht, useState as F, useEffect as D, createElement as u, useRef as M, createContext as gt, useCallback as X, Fragment as xt } from "react";
import { b as _, a as z } from "./index-CTDw4Pi4.js";
import { $ as _t } from "./index-egs6Xnuq.js";
import { $ as ke, a as Oe } from "./index-Cp-Nu9_j.js";
import { $ as wt } from "./index-U5S6G_il.js";
import { $ as N, a as Ne } from "./index-CZBInFmv.js";
import { $ as Pt } from "./index-En-smfNl.js";
import { h as Ct, $ as Et, R as yt } from "./Combination-DGcoLHGm.js";
import { $ as Te } from "./index-rKUeoTl7.js";
import { $ as Mt, u as Ot, a as Tt, o as Dt, s as St, l as It, f as Ft, b as At, c as Rt, h as Lt } from "./index-DoIowKQ-.js";
import { $ as kt } from "./index-DaaF0TLE.js";
import Nt from "react-dom";
import { $ as pe } from "./index-Cs5eRmmP.js";
import { $ as Ke, a as Kt, b as Ut } from "./index-BR2J6Bm_.js";
const xe = "dismissableLayer.update", Gt = "dismissableLayer.pointerDownOutside", Wt = "dismissableLayer.focusOutside";
let De;
const Bt = /* @__PURE__ */ gt({
  layers: /* @__PURE__ */ new Set(),
  layersWithOutsidePointerEventsDisabled: /* @__PURE__ */ new Set(),
  branches: /* @__PURE__ */ new Set()
}), Ht = /* @__PURE__ */ C((e, n) => {
  var t;
  const { disableOutsidePointerEvents: o = !1, onEscapeKeyDown: c, onPointerDownOutside: r, onFocusOutside: i, onInteractOutside: s, onDismiss: f, ...p } = e, d = ht(Bt), [a, m] = F(null), l = (t = a == null ? void 0 : a.ownerDocument) !== null && t !== void 0 ? t : globalThis == null ? void 0 : globalThis.document, [, b] = F({}), v = W(
    n,
    (E) => m(E)
  ), w = Array.from(d.layers), [h] = [
    ...d.layersWithOutsidePointerEventsDisabled
  ].slice(-1), O = w.indexOf(h), x = a ? w.indexOf(a) : -1, P = d.layersWithOutsidePointerEventsDisabled.size > 0, K = x >= O, ee = Vt((E) => {
    const I = E.target, U = [
      ...d.branches
    ].some(
      (Z) => Z.contains(I)
    );
    !K || U || (r == null || r(E), s == null || s(E), E.defaultPrevented || f == null || f());
  }, l), L = Yt((E) => {
    const I = E.target;
    [
      ...d.branches
    ].some(
      (Z) => Z.contains(I)
    ) || (i == null || i(E), s == null || s(E), E.defaultPrevented || f == null || f());
  }, l);
  return Pt((E) => {
    x === d.layers.size - 1 && (c == null || c(E), !E.defaultPrevented && f && (E.preventDefault(), f()));
  }, l), D(() => {
    if (a)
      return o && (d.layersWithOutsidePointerEventsDisabled.size === 0 && (De = l.body.style.pointerEvents, l.body.style.pointerEvents = "none"), d.layersWithOutsidePointerEventsDisabled.add(a)), d.layers.add(a), Se(), () => {
        o && d.layersWithOutsidePointerEventsDisabled.size === 1 && (l.body.style.pointerEvents = De);
      };
  }, [
    a,
    l,
    o,
    d
  ]), D(() => () => {
    a && (d.layers.delete(a), d.layersWithOutsidePointerEventsDisabled.delete(a), Se());
  }, [
    a,
    d
  ]), D(() => {
    const E = () => b({});
    return document.addEventListener(xe, E), () => document.removeEventListener(xe, E);
  }, []), /* @__PURE__ */ u(N.div, g({}, p, {
    ref: v,
    style: {
      pointerEvents: P ? K ? "auto" : "none" : void 0,
      ...e.style
    },
    onFocusCapture: _(e.onFocusCapture, L.onFocusCapture),
    onBlurCapture: _(e.onBlurCapture, L.onBlurCapture),
    onPointerDownCapture: _(e.onPointerDownCapture, ee.onPointerDownCapture)
  }));
});
function Vt(e, n = globalThis == null ? void 0 : globalThis.document) {
  const t = z(e), o = M(!1), c = M(() => {
  });
  return D(() => {
    const r = (s) => {
      if (s.target && !o.current) {
        let p = function() {
          Ue(Gt, t, f, {
            discrete: !0
          });
        };
        const f = {
          originalEvent: s
        };
        s.pointerType === "touch" ? (n.removeEventListener("click", c.current), c.current = p, n.addEventListener("click", c.current, {
          once: !0
        })) : p();
      } else
        n.removeEventListener("click", c.current);
      o.current = !1;
    }, i = window.setTimeout(() => {
      n.addEventListener("pointerdown", r);
    }, 0);
    return () => {
      window.clearTimeout(i), n.removeEventListener("pointerdown", r), n.removeEventListener("click", c.current);
    };
  }, [
    n,
    t
  ]), {
    // ensures we check React component tree (not just DOM tree)
    onPointerDownCapture: () => o.current = !0
  };
}
function Yt(e, n = globalThis == null ? void 0 : globalThis.document) {
  const t = z(e), o = M(!1);
  return D(() => {
    const c = (r) => {
      r.target && !o.current && Ue(Wt, t, {
        originalEvent: r
      }, {
        discrete: !1
      });
    };
    return n.addEventListener("focusin", c), () => n.removeEventListener("focusin", c);
  }, [
    n,
    t
  ]), {
    onFocusCapture: () => o.current = !0,
    onBlurCapture: () => o.current = !1
  };
}
function Se() {
  const e = new CustomEvent(xe);
  document.dispatchEvent(e);
}
function Ue(e, n, t, { discrete: o }) {
  const c = t.originalEvent.target, r = new CustomEvent(e, {
    bubbles: !1,
    cancelable: !0,
    detail: t
  });
  n && c.addEventListener(e, n, {
    once: !0
  }), o ? Ne(c, r) : c.dispatchEvent(r);
}
const he = "focusScope.autoFocusOnMount", ge = "focusScope.autoFocusOnUnmount", Ie = {
  bubbles: !1,
  cancelable: !0
}, Xt = /* @__PURE__ */ C((e, n) => {
  const { loop: t = !1, trapped: o = !1, onMountAutoFocus: c, onUnmountAutoFocus: r, ...i } = e, [s, f] = F(null), p = z(c), d = z(r), a = M(null), m = W(
    n,
    (v) => f(v)
  ), l = M({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  D(() => {
    if (o) {
      let v = function(x) {
        if (l.paused || !s) return;
        const P = x.target;
        s.contains(P) ? a.current = P : Y(a.current, {
          select: !0
        });
      }, w = function(x) {
        if (l.paused || !s) return;
        const P = x.relatedTarget;
        P !== null && (s.contains(P) || Y(a.current, {
          select: !0
        }));
      }, h = function(x) {
        if (document.activeElement === document.body)
          for (const K of x) K.removedNodes.length > 0 && Y(s);
      };
      document.addEventListener("focusin", v), document.addEventListener("focusout", w);
      const O = new MutationObserver(h);
      return s && O.observe(s, {
        childList: !0,
        subtree: !0
      }), () => {
        document.removeEventListener("focusin", v), document.removeEventListener("focusout", w), O.disconnect();
      };
    }
  }, [
    o,
    s,
    l.paused
  ]), D(() => {
    if (s) {
      Ae.add(l);
      const v = document.activeElement;
      if (!s.contains(v)) {
        const h = new CustomEvent(he, Ie);
        s.addEventListener(he, p), s.dispatchEvent(h), h.defaultPrevented || (zt(Qt(Ge(s)), {
          select: !0
        }), document.activeElement === v && Y(s));
      }
      return () => {
        s.removeEventListener(he, p), setTimeout(() => {
          const h = new CustomEvent(ge, Ie);
          s.addEventListener(ge, d), s.dispatchEvent(h), h.defaultPrevented || Y(v ?? document.body, {
            select: !0
          }), s.removeEventListener(ge, d), Ae.remove(l);
        }, 0);
      };
    }
  }, [
    s,
    p,
    d,
    l
  ]);
  const b = X((v) => {
    if (!t && !o || l.paused) return;
    const w = v.key === "Tab" && !v.altKey && !v.ctrlKey && !v.metaKey, h = document.activeElement;
    if (w && h) {
      const O = v.currentTarget, [x, P] = jt(O);
      x && P ? !v.shiftKey && h === P ? (v.preventDefault(), t && Y(x, {
        select: !0
      })) : v.shiftKey && h === x && (v.preventDefault(), t && Y(P, {
        select: !0
      })) : h === O && v.preventDefault();
    }
  }, [
    t,
    o,
    l.paused
  ]);
  return /* @__PURE__ */ u(N.div, g({
    tabIndex: -1
  }, i, {
    ref: m,
    onKeyDown: b
  }));
});
function zt(e, { select: n = !1 } = {}) {
  const t = document.activeElement;
  for (const o of e)
    if (Y(o, {
      select: n
    }), document.activeElement !== t) return;
}
function jt(e) {
  const n = Ge(e), t = Fe(n, e), o = Fe(n.reverse(), e);
  return [
    t,
    o
  ];
}
function Ge(e) {
  const n = [], t = document.createTreeWalker(e, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (o) => {
      const c = o.tagName === "INPUT" && o.type === "hidden";
      return o.disabled || o.hidden || c ? NodeFilter.FILTER_SKIP : o.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; t.nextNode(); ) n.push(t.currentNode);
  return n;
}
function Fe(e, n) {
  for (const t of e)
    if (!Zt(t, {
      upTo: n
    })) return t;
}
function Zt(e, { upTo: n }) {
  if (getComputedStyle(e).visibility === "hidden") return !0;
  for (; e; ) {
    if (n !== void 0 && e === n) return !1;
    if (getComputedStyle(e).display === "none") return !0;
    e = e.parentElement;
  }
  return !1;
}
function qt(e) {
  return e instanceof HTMLInputElement && "select" in e;
}
function Y(e, { select: n = !1 } = {}) {
  if (e && e.focus) {
    const t = document.activeElement;
    e.focus({
      preventScroll: !0
    }), e !== t && qt(e) && n && e.select();
  }
}
const Ae = Jt();
function Jt() {
  let e = [];
  return {
    add(n) {
      const t = e[0];
      n !== t && (t == null || t.pause()), e = Re(e, n), e.unshift(n);
    },
    remove(n) {
      var t;
      e = Re(e, n), (t = e[0]) === null || t === void 0 || t.resume();
    }
  };
}
function Re(e, n) {
  const t = [
    ...e
  ], o = t.indexOf(n);
  return o !== -1 && t.splice(o, 1), t;
}
function Qt(e) {
  return e.filter(
    (n) => n.tagName !== "A"
  );
}
const We = "Popper", [Be, He] = ke(We), [en, Ve] = Be(We), tn = (e) => {
  const { __scopePopper: n, children: t } = e, [o, c] = F(null);
  return /* @__PURE__ */ u(en, {
    scope: n,
    anchor: o,
    onAnchorChange: c
  }, t);
}, nn = "PopperAnchor", on = /* @__PURE__ */ C((e, n) => {
  const { __scopePopper: t, virtualRef: o, ...c } = e, r = Ve(nn, t), i = M(null), s = W(n, i);
  return D(() => {
    r.onAnchorChange((o == null ? void 0 : o.current) || i.current);
  }), o ? null : /* @__PURE__ */ u(N.div, g({}, c, {
    ref: s
  }));
}), Ye = "PopperContent", [cn, rn] = Be(Ye), an = /* @__PURE__ */ C((e, n) => {
  var t, o, c, r, i, s, f, p;
  const { __scopePopper: d, side: a = "bottom", sideOffset: m = 0, align: l = "center", alignOffset: b = 0, arrowPadding: v = 0, avoidCollisions: w = !0, collisionBoundary: h = [], collisionPadding: O = 0, sticky: x = "partial", hideWhenDetached: P = !1, updatePositionStrategy: K = "optimized", onPlaced: ee, ...L } = e, E = Ve(Ye, d), [I, U] = F(null), Z = W(
    n,
    (ne) => U(ne)
  ), [q, se] = F(null), k = kt(q), me = (t = k == null ? void 0 : k.width) !== null && t !== void 0 ? t : 0, ie = (o = k == null ? void 0 : k.height) !== null && o !== void 0 ? o : 0, be = a + (l !== "center" ? "-" + l : ""), B = typeof O == "number" ? O : {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...O
  }, $ = Array.isArray(h) ? h : [
    h
  ], y = $.length > 0, T = {
    padding: B,
    boundary: $.filter(ln),
    // with `strategy: 'fixed'`, this is the only way to get it to respect boundaries
    altBoundary: y
  }, { refs: G, floatingStyles: H, placement: te, isPositioned: J, middlewareData: A } = Ot({
    // default to `fixed` strategy so users don't have to pick and we also avoid focus scroll issues
    strategy: "fixed",
    placement: be,
    whileElementsMounted: (...ne) => Tt(...ne, {
      animationFrame: K === "always"
    }),
    elements: {
      reference: E.anchor
    },
    middleware: [
      Dt({
        mainAxis: m + ie,
        alignmentAxis: b
      }),
      w && St({
        mainAxis: !0,
        crossAxis: !1,
        limiter: x === "partial" ? It() : void 0,
        ...T
      }),
      w && Ft({
        ...T
      }),
      At({
        ...T,
        apply: ({ elements: ne, rects: Me, availableWidth: ft, availableHeight: $t }) => {
          const { width: pt, height: vt } = Me.reference, le = ne.floating.style;
          le.setProperty("--radix-popper-available-width", `${ft}px`), le.setProperty("--radix-popper-available-height", `${$t}px`), le.setProperty("--radix-popper-anchor-width", `${pt}px`), le.setProperty("--radix-popper-anchor-height", `${vt}px`);
        }
      }),
      q && Rt({
        element: q,
        padding: v
      }),
      fn({
        arrowWidth: me,
        arrowHeight: ie
      }),
      P && Lt({
        strategy: "referenceHidden",
        ...T
      })
    ]
  }), [V, de] = Xe(te), S = z(ee);
  Oe(() => {
    J && (S == null || S());
  }, [
    J,
    S
  ]);
  const ue = (c = A.arrow) === null || c === void 0 ? void 0 : c.x, it = (r = A.arrow) === null || r === void 0 ? void 0 : r.y, dt = ((i = A.arrow) === null || i === void 0 ? void 0 : i.centerOffset) !== 0, [ut, lt] = F();
  return Oe(() => {
    I && lt(window.getComputedStyle(I).zIndex);
  }, [
    I
  ]), /* @__PURE__ */ u("div", {
    ref: G.setFloating,
    "data-radix-popper-content-wrapper": "",
    style: {
      ...H,
      transform: J ? H.transform : "translate(0, -200%)",
      // keep off the page when measuring
      minWidth: "max-content",
      zIndex: ut,
      "--radix-popper-transform-origin": [
        (s = A.transformOrigin) === null || s === void 0 ? void 0 : s.x,
        (f = A.transformOrigin) === null || f === void 0 ? void 0 : f.y
      ].join(" ")
    },
    dir: e.dir
  }, /* @__PURE__ */ u(cn, {
    scope: d,
    placedSide: V,
    onArrowChange: se,
    arrowX: ue,
    arrowY: it,
    shouldHideArrow: dt
  }, /* @__PURE__ */ u(N.div, g({
    "data-side": V,
    "data-align": de
  }, L, {
    ref: Z,
    style: {
      ...L.style,
      // if the PopperContent hasn't been placed yet (not all measurements done)
      // we prevent animations so that users's animation don't kick in too early referring wrong sides
      animation: J ? void 0 : "none",
      // hide the content if using the hide middleware and should be hidden
      opacity: (p = A.hide) !== null && p !== void 0 && p.referenceHidden ? 0 : void 0
    }
  }))));
}), sn = "PopperArrow", dn = {
  top: "bottom",
  right: "left",
  bottom: "top",
  left: "right"
}, un = /* @__PURE__ */ C(function(n, t) {
  const { __scopePopper: o, ...c } = n, r = rn(sn, o), i = dn[r.placedSide];
  return (
    // we have to use an extra wrapper because `ResizeObserver` (used by `useSize`)
    // doesn't report size as we'd expect on SVG elements.
    // it reports their bounding box which is effectively the largest path inside the SVG.
    /* @__PURE__ */ u("span", {
      ref: r.onArrowChange,
      style: {
        position: "absolute",
        left: r.arrowX,
        top: r.arrowY,
        [i]: 0,
        transformOrigin: {
          top: "",
          right: "0 0",
          bottom: "center 0",
          left: "100% 0"
        }[r.placedSide],
        transform: {
          top: "translateY(100%)",
          right: "translateY(50%) rotate(90deg) translateX(-50%)",
          bottom: "rotate(180deg)",
          left: "translateY(50%) rotate(-90deg) translateX(50%)"
        }[r.placedSide],
        visibility: r.shouldHideArrow ? "hidden" : void 0
      }
    }, /* @__PURE__ */ u(Mt, g({}, c, {
      ref: t,
      style: {
        ...c.style,
        // ensures the element can be measured correctly (mostly for if SVG)
        display: "block"
      }
    })))
  );
});
function ln(e) {
  return e !== null;
}
const fn = (e) => ({
  name: "transformOrigin",
  options: e,
  fn(n) {
    var t, o, c, r, i;
    const { placement: s, rects: f, middlewareData: p } = n, a = ((t = p.arrow) === null || t === void 0 ? void 0 : t.centerOffset) !== 0, m = a ? 0 : e.arrowWidth, l = a ? 0 : e.arrowHeight, [b, v] = Xe(s), w = {
      start: "0%",
      center: "50%",
      end: "100%"
    }[v], h = ((o = (c = p.arrow) === null || c === void 0 ? void 0 : c.x) !== null && o !== void 0 ? o : 0) + m / 2, O = ((r = (i = p.arrow) === null || i === void 0 ? void 0 : i.y) !== null && r !== void 0 ? r : 0) + l / 2;
    let x = "", P = "";
    return b === "bottom" ? (x = a ? w : `${h}px`, P = `${-l}px`) : b === "top" ? (x = a ? w : `${h}px`, P = `${f.floating.height + l}px`) : b === "right" ? (x = `${-l}px`, P = a ? w : `${O}px`) : b === "left" && (x = `${f.floating.width + l}px`, P = a ? w : `${O}px`), {
      data: {
        x,
        y: P
      }
    };
  }
});
function Xe(e) {
  const [n, t = "center"] = e.split("-");
  return [
    n,
    t
  ];
}
const ze = tn, $n = on, pn = an, vn = un, mn = /* @__PURE__ */ C((e, n) => {
  var t;
  const { container: o = globalThis == null || (t = globalThis.document) === null || t === void 0 ? void 0 : t.body, ...c } = e;
  return o ? /* @__PURE__ */ Nt.createPortal(/* @__PURE__ */ u(N.div, g({}, c, {
    ref: n
  })), o) : null;
}), _e = [
  "Enter",
  " "
], bn = [
  "ArrowDown",
  "PageUp",
  "Home"
], je = [
  "ArrowUp",
  "PageDown",
  "End"
], hn = [
  ...bn,
  ...je
], gn = {
  ltr: [
    ..._e,
    "ArrowRight"
  ],
  rtl: [
    ..._e,
    "ArrowLeft"
  ]
}, xn = {
  ltr: [
    "ArrowLeft"
  ],
  rtl: [
    "ArrowRight"
  ]
}, ve = "Menu", [oe, _n, wn] = _t(ve), [Q, vo] = ke(ve, [
  wn,
  He,
  Ke
]), re = He(), Ze = Ke(), [qe, j] = Q(ve), [Pn, ae] = Q(ve), Cn = (e) => {
  const { __scopeMenu: n, open: t = !1, children: o, dir: c, onOpenChange: r, modal: i = !0 } = e, s = re(n), [f, p] = F(null), d = M(!1), a = z(r), m = wt(c);
  return D(() => {
    const l = () => {
      d.current = !0, document.addEventListener("pointerdown", b, {
        capture: !0,
        once: !0
      }), document.addEventListener("pointermove", b, {
        capture: !0,
        once: !0
      });
    }, b = () => d.current = !1;
    return document.addEventListener("keydown", l, {
      capture: !0
    }), () => {
      document.removeEventListener("keydown", l, {
        capture: !0
      }), document.removeEventListener("pointerdown", b, {
        capture: !0
      }), document.removeEventListener("pointermove", b, {
        capture: !0
      });
    };
  }, []), /* @__PURE__ */ u(ze, s, /* @__PURE__ */ u(qe, {
    scope: n,
    open: t,
    onOpenChange: a,
    content: f,
    onContentChange: p
  }, /* @__PURE__ */ u(Pn, {
    scope: n,
    onClose: X(
      () => a(!1),
      [
        a
      ]
    ),
    isUsingKeyboardRef: d,
    dir: m,
    modal: i
  }, o)));
}, Je = /* @__PURE__ */ C((e, n) => {
  const { __scopeMenu: t, ...o } = e, c = re(t);
  return /* @__PURE__ */ u($n, g({}, c, o, {
    ref: n
  }));
}), Qe = "MenuPortal", [En, et] = Q(Qe, {
  forceMount: void 0
}), yn = (e) => {
  const { __scopeMenu: n, forceMount: t, children: o, container: c } = e, r = j(Qe, n);
  return /* @__PURE__ */ u(En, {
    scope: n,
    forceMount: t
  }, /* @__PURE__ */ u(pe, {
    present: t || r.open
  }, /* @__PURE__ */ u(mn, {
    asChild: !0,
    container: c
  }, o)));
}, R = "MenuContent", [Mn, Pe] = Q(R), On = /* @__PURE__ */ C((e, n) => {
  const t = et(R, e.__scopeMenu), { forceMount: o = t.forceMount, ...c } = e, r = j(R, e.__scopeMenu), i = ae(R, e.__scopeMenu);
  return /* @__PURE__ */ u(oe.Provider, {
    scope: e.__scopeMenu
  }, /* @__PURE__ */ u(pe, {
    present: o || r.open
  }, /* @__PURE__ */ u(oe.Slot, {
    scope: e.__scopeMenu
  }, i.modal ? /* @__PURE__ */ u(Tn, g({}, c, {
    ref: n
  })) : /* @__PURE__ */ u(Dn, g({}, c, {
    ref: n
  })))));
}), Tn = /* @__PURE__ */ C((e, n) => {
  const t = j(R, e.__scopeMenu), o = M(null), c = W(n, o);
  return D(() => {
    const r = o.current;
    if (r) return Ct(r);
  }, []), /* @__PURE__ */ u(Ce, g({}, e, {
    ref: c,
    trapFocus: t.open,
    disableOutsidePointerEvents: t.open,
    disableOutsideScroll: !0,
    onFocusOutside: _(
      e.onFocusOutside,
      (r) => r.preventDefault(),
      {
        checkForDefaultPrevented: !1
      }
    ),
    onDismiss: () => t.onOpenChange(!1)
  }));
}), Dn = /* @__PURE__ */ C((e, n) => {
  const t = j(R, e.__scopeMenu);
  return /* @__PURE__ */ u(Ce, g({}, e, {
    ref: n,
    trapFocus: !1,
    disableOutsidePointerEvents: !1,
    disableOutsideScroll: !1,
    onDismiss: () => t.onOpenChange(!1)
  }));
}), Ce = /* @__PURE__ */ C((e, n) => {
  const { __scopeMenu: t, loop: o = !1, trapFocus: c, onOpenAutoFocus: r, onCloseAutoFocus: i, disableOutsidePointerEvents: s, onEntryFocus: f, onEscapeKeyDown: p, onPointerDownOutside: d, onFocusOutside: a, onInteractOutside: m, onDismiss: l, disableOutsideScroll: b, ...v } = e, w = j(R, t), h = ae(R, t), O = re(t), x = Ze(t), P = _n(t), [K, ee] = F(null), L = M(null), E = W(n, L, w.onContentChange), I = M(0), U = M(""), Z = M(0), q = M(null), se = M("right"), k = M(0), me = b ? yt : xt, ie = b ? {
    as: bt,
    allowPinchZoom: !0
  } : void 0, be = ($) => {
    var y, T;
    const G = U.current + $, H = P().filter(
      (S) => !S.disabled
    ), te = document.activeElement, J = (y = H.find(
      (S) => S.ref.current === te
    )) === null || y === void 0 ? void 0 : y.textValue, A = H.map(
      (S) => S.textValue
    ), V = Zn(A, G, J), de = (T = H.find(
      (S) => S.textValue === V
    )) === null || T === void 0 ? void 0 : T.ref.current;
    (function S(ue) {
      U.current = ue, window.clearTimeout(I.current), ue !== "" && (I.current = window.setTimeout(
        () => S(""),
        1e3
      ));
    })(G), de && setTimeout(
      () => de.focus()
    );
  };
  D(() => () => window.clearTimeout(I.current), []), Et();
  const B = X(($) => {
    var y, T;
    return se.current === ((y = q.current) === null || y === void 0 ? void 0 : y.side) && Jn($, (T = q.current) === null || T === void 0 ? void 0 : T.area);
  }, []);
  return /* @__PURE__ */ u(Mn, {
    scope: t,
    searchRef: U,
    onItemEnter: X(($) => {
      B($) && $.preventDefault();
    }, [
      B
    ]),
    onItemLeave: X(($) => {
      var y;
      B($) || ((y = L.current) === null || y === void 0 || y.focus(), ee(null));
    }, [
      B
    ]),
    onTriggerLeave: X(($) => {
      B($) && $.preventDefault();
    }, [
      B
    ]),
    pointerGraceTimerRef: Z,
    onPointerGraceIntentChange: X(($) => {
      q.current = $;
    }, [])
  }, /* @__PURE__ */ u(me, ie, /* @__PURE__ */ u(Xt, {
    asChild: !0,
    trapped: c,
    onMountAutoFocus: _(r, ($) => {
      var y;
      $.preventDefault(), (y = L.current) === null || y === void 0 || y.focus();
    }),
    onUnmountAutoFocus: i
  }, /* @__PURE__ */ u(Ht, {
    asChild: !0,
    disableOutsidePointerEvents: s,
    onEscapeKeyDown: p,
    onPointerDownOutside: d,
    onFocusOutside: a,
    onInteractOutside: m,
    onDismiss: l
  }, /* @__PURE__ */ u(Kt, g({
    asChild: !0
  }, x, {
    dir: h.dir,
    orientation: "vertical",
    loop: o,
    currentTabStopId: K,
    onCurrentTabStopIdChange: ee,
    onEntryFocus: _(f, ($) => {
      h.isUsingKeyboardRef.current || $.preventDefault();
    })
  }), /* @__PURE__ */ u(pn, g({
    role: "menu",
    "aria-orientation": "vertical",
    "data-state": st(w.open),
    "data-radix-menu-content": "",
    dir: h.dir
  }, O, v, {
    ref: E,
    style: {
      outline: "none",
      ...v.style
    },
    onKeyDown: _(v.onKeyDown, ($) => {
      const T = $.target.closest("[data-radix-menu-content]") === $.currentTarget, G = $.ctrlKey || $.altKey || $.metaKey, H = $.key.length === 1;
      T && ($.key === "Tab" && $.preventDefault(), !G && H && be($.key));
      const te = L.current;
      if ($.target !== te || !hn.includes($.key)) return;
      $.preventDefault();
      const A = P().filter(
        (V) => !V.disabled
      ).map(
        (V) => V.ref.current
      );
      je.includes($.key) && A.reverse(), zn(A);
    }),
    onBlur: _(e.onBlur, ($) => {
      $.currentTarget.contains($.target) || (window.clearTimeout(I.current), U.current = "");
    }),
    onPointerMove: _(e.onPointerMove, ce(($) => {
      const y = $.target, T = k.current !== $.clientX;
      if ($.currentTarget.contains(y) && T) {
        const G = $.clientX > k.current ? "right" : "left";
        se.current = G, k.current = $.clientX;
      }
    }))
  })))))));
}), tt = /* @__PURE__ */ C((e, n) => {
  const { __scopeMenu: t, ...o } = e;
  return /* @__PURE__ */ u(N.div, g({
    role: "group"
  }, o, {
    ref: n
  }));
}), Sn = /* @__PURE__ */ C((e, n) => {
  const { __scopeMenu: t, ...o } = e;
  return /* @__PURE__ */ u(N.div, g({}, o, {
    ref: n
  }));
}), we = "MenuItem", Le = "menu.itemSelect", Ee = /* @__PURE__ */ C((e, n) => {
  const { disabled: t = !1, onSelect: o, ...c } = e, r = M(null), i = ae(we, e.__scopeMenu), s = Pe(we, e.__scopeMenu), f = W(n, r), p = M(!1), d = () => {
    const a = r.current;
    if (!t && a) {
      const m = new CustomEvent(Le, {
        bubbles: !0,
        cancelable: !0
      });
      a.addEventListener(
        Le,
        (l) => o == null ? void 0 : o(l),
        {
          once: !0
        }
      ), Ne(a, m), m.defaultPrevented ? p.current = !1 : i.onClose();
    }
  };
  return /* @__PURE__ */ u(nt, g({}, c, {
    ref: f,
    disabled: t,
    onClick: _(e.onClick, d),
    onPointerDown: (a) => {
      var m;
      (m = e.onPointerDown) === null || m === void 0 || m.call(e, a), p.current = !0;
    },
    onPointerUp: _(e.onPointerUp, (a) => {
      var m;
      p.current || (m = a.currentTarget) === null || m === void 0 || m.click();
    }),
    onKeyDown: _(e.onKeyDown, (a) => {
      const m = s.searchRef.current !== "";
      t || m && a.key === " " || _e.includes(a.key) && (a.currentTarget.click(), a.preventDefault());
    })
  }));
}), nt = /* @__PURE__ */ C((e, n) => {
  const { __scopeMenu: t, disabled: o = !1, textValue: c, ...r } = e, i = Pe(we, t), s = Ze(t), f = M(null), p = W(n, f), [d, a] = F(!1), [m, l] = F("");
  return D(() => {
    const b = f.current;
    if (b) {
      var v;
      l(((v = b.textContent) !== null && v !== void 0 ? v : "").trim());
    }
  }, [
    r.children
  ]), /* @__PURE__ */ u(oe.ItemSlot, {
    scope: t,
    disabled: o,
    textValue: c ?? m
  }, /* @__PURE__ */ u(Ut, g({
    asChild: !0
  }, s, {
    focusable: !o
  }), /* @__PURE__ */ u(N.div, g({
    role: "menuitem",
    "data-highlighted": d ? "" : void 0,
    "aria-disabled": o || void 0,
    "data-disabled": o ? "" : void 0
  }, r, {
    ref: p,
    onPointerMove: _(e.onPointerMove, ce((b) => {
      o ? i.onItemLeave(b) : (i.onItemEnter(b), b.defaultPrevented || b.currentTarget.focus());
    })),
    onPointerLeave: _(e.onPointerLeave, ce(
      (b) => i.onItemLeave(b)
    )),
    onFocus: _(
      e.onFocus,
      () => a(!0)
    ),
    onBlur: _(
      e.onBlur,
      () => a(!1)
    )
  }))));
}), In = /* @__PURE__ */ C((e, n) => {
  const { checked: t = !1, onCheckedChange: o, ...c } = e;
  return /* @__PURE__ */ u(ct, {
    scope: e.__scopeMenu,
    checked: t
  }, /* @__PURE__ */ u(Ee, g({
    role: "menuitemcheckbox",
    "aria-checked": $e(t) ? "mixed" : t
  }, c, {
    ref: n,
    "data-state": ye(t),
    onSelect: _(
      c.onSelect,
      () => o == null ? void 0 : o($e(t) ? !0 : !t),
      {
        checkForDefaultPrevented: !1
      }
    )
  })));
}), Fn = "MenuRadioGroup", [An, Rn] = Q(Fn, {
  value: void 0,
  onValueChange: () => {
  }
}), Ln = /* @__PURE__ */ C((e, n) => {
  const { value: t, onValueChange: o, ...c } = e, r = z(o);
  return /* @__PURE__ */ u(An, {
    scope: e.__scopeMenu,
    value: t,
    onValueChange: r
  }, /* @__PURE__ */ u(tt, g({}, c, {
    ref: n
  })));
}), kn = "MenuRadioItem", Nn = /* @__PURE__ */ C((e, n) => {
  const { value: t, ...o } = e, c = Rn(kn, e.__scopeMenu), r = t === c.value;
  return /* @__PURE__ */ u(ct, {
    scope: e.__scopeMenu,
    checked: r
  }, /* @__PURE__ */ u(Ee, g({
    role: "menuitemradio",
    "aria-checked": r
  }, o, {
    ref: n,
    "data-state": ye(r),
    onSelect: _(o.onSelect, () => {
      var i;
      return (i = c.onValueChange) === null || i === void 0 ? void 0 : i.call(c, t);
    }, {
      checkForDefaultPrevented: !1
    })
  })));
}), ot = "MenuItemIndicator", [ct, Kn] = Q(ot, {
  checked: !1
}), Un = /* @__PURE__ */ C((e, n) => {
  const { __scopeMenu: t, forceMount: o, ...c } = e, r = Kn(ot, t);
  return /* @__PURE__ */ u(pe, {
    present: o || $e(r.checked) || r.checked === !0
  }, /* @__PURE__ */ u(N.span, g({}, c, {
    ref: n,
    "data-state": ye(r.checked)
  })));
}), Gn = /* @__PURE__ */ C((e, n) => {
  const { __scopeMenu: t, ...o } = e;
  return /* @__PURE__ */ u(N.div, g({
    role: "separator",
    "aria-orientation": "horizontal"
  }, o, {
    ref: n
  }));
}), Wn = /* @__PURE__ */ C((e, n) => {
  const { __scopeMenu: t, ...o } = e, c = re(t);
  return /* @__PURE__ */ u(vn, g({}, c, o, {
    ref: n
  }));
}), rt = "MenuSub", [Bn, at] = Q(rt), Hn = (e) => {
  const { __scopeMenu: n, children: t, open: o = !1, onOpenChange: c } = e, r = j(rt, n), i = re(n), [s, f] = F(null), [p, d] = F(null), a = z(c);
  return D(() => (r.open === !1 && a(!1), () => a(!1)), [
    r.open,
    a
  ]), /* @__PURE__ */ u(ze, i, /* @__PURE__ */ u(qe, {
    scope: n,
    open: o,
    onOpenChange: a,
    content: p,
    onContentChange: d
  }, /* @__PURE__ */ u(Bn, {
    scope: n,
    contentId: Te(),
    triggerId: Te(),
    trigger: s,
    onTriggerChange: f
  }, t)));
}, fe = "MenuSubTrigger", Vn = /* @__PURE__ */ C((e, n) => {
  const t = j(fe, e.__scopeMenu), o = ae(fe, e.__scopeMenu), c = at(fe, e.__scopeMenu), r = Pe(fe, e.__scopeMenu), i = M(null), { pointerGraceTimerRef: s, onPointerGraceIntentChange: f } = r, p = {
    __scopeMenu: e.__scopeMenu
  }, d = X(() => {
    i.current && window.clearTimeout(i.current), i.current = null;
  }, []);
  return D(
    () => d,
    [
      d
    ]
  ), D(() => {
    const a = s.current;
    return () => {
      window.clearTimeout(a), f(null);
    };
  }, [
    s,
    f
  ]), /* @__PURE__ */ u(Je, g({
    asChild: !0
  }, p), /* @__PURE__ */ u(nt, g({
    id: c.triggerId,
    "aria-haspopup": "menu",
    "aria-expanded": t.open,
    "aria-controls": c.contentId,
    "data-state": st(t.open)
  }, e, {
    ref: mt(n, c.onTriggerChange),
    onClick: (a) => {
      var m;
      (m = e.onClick) === null || m === void 0 || m.call(e, a), !(e.disabled || a.defaultPrevented) && (a.currentTarget.focus(), t.open || t.onOpenChange(!0));
    },
    onPointerMove: _(e.onPointerMove, ce((a) => {
      r.onItemEnter(a), !a.defaultPrevented && !e.disabled && !t.open && !i.current && (r.onPointerGraceIntentChange(null), i.current = window.setTimeout(() => {
        t.onOpenChange(!0), d();
      }, 100));
    })),
    onPointerLeave: _(e.onPointerLeave, ce((a) => {
      var m;
      d();
      const l = (m = t.content) === null || m === void 0 ? void 0 : m.getBoundingClientRect();
      if (l) {
        var b;
        const v = (b = t.content) === null || b === void 0 ? void 0 : b.dataset.side, w = v === "right", h = w ? -5 : 5, O = l[w ? "left" : "right"], x = l[w ? "right" : "left"];
        r.onPointerGraceIntentChange({
          area: [
            // consistently within polygon bounds
            {
              x: a.clientX + h,
              y: a.clientY
            },
            {
              x: O,
              y: l.top
            },
            {
              x,
              y: l.top
            },
            {
              x,
              y: l.bottom
            },
            {
              x: O,
              y: l.bottom
            }
          ],
          side: v
        }), window.clearTimeout(s.current), s.current = window.setTimeout(
          () => r.onPointerGraceIntentChange(null),
          300
        );
      } else {
        if (r.onTriggerLeave(a), a.defaultPrevented) return;
        r.onPointerGraceIntentChange(null);
      }
    })),
    onKeyDown: _(e.onKeyDown, (a) => {
      const m = r.searchRef.current !== "";
      if (!(e.disabled || m && a.key === " ") && gn[o.dir].includes(a.key)) {
        var l;
        t.onOpenChange(!0), (l = t.content) === null || l === void 0 || l.focus(), a.preventDefault();
      }
    })
  })));
}), Yn = "MenuSubContent", Xn = /* @__PURE__ */ C((e, n) => {
  const t = et(R, e.__scopeMenu), { forceMount: o = t.forceMount, ...c } = e, r = j(R, e.__scopeMenu), i = ae(R, e.__scopeMenu), s = at(Yn, e.__scopeMenu), f = M(null), p = W(n, f);
  return /* @__PURE__ */ u(oe.Provider, {
    scope: e.__scopeMenu
  }, /* @__PURE__ */ u(pe, {
    present: o || r.open
  }, /* @__PURE__ */ u(oe.Slot, {
    scope: e.__scopeMenu
  }, /* @__PURE__ */ u(Ce, g({
    id: s.contentId,
    "aria-labelledby": s.triggerId
  }, c, {
    ref: p,
    align: "start",
    side: i.dir === "rtl" ? "left" : "right",
    disableOutsidePointerEvents: !1,
    disableOutsideScroll: !1,
    trapFocus: !1,
    onOpenAutoFocus: (d) => {
      var a;
      i.isUsingKeyboardRef.current && ((a = f.current) === null || a === void 0 || a.focus()), d.preventDefault();
    },
    onCloseAutoFocus: (d) => d.preventDefault(),
    onFocusOutside: _(e.onFocusOutside, (d) => {
      d.target !== s.trigger && r.onOpenChange(!1);
    }),
    onEscapeKeyDown: _(e.onEscapeKeyDown, (d) => {
      i.onClose(), d.preventDefault();
    }),
    onKeyDown: _(e.onKeyDown, (d) => {
      const a = d.currentTarget.contains(d.target), m = xn[i.dir].includes(d.key);
      if (a && m) {
        var l;
        r.onOpenChange(!1), (l = s.trigger) === null || l === void 0 || l.focus(), d.preventDefault();
      }
    })
  })))));
});
function st(e) {
  return e ? "open" : "closed";
}
function $e(e) {
  return e === "indeterminate";
}
function ye(e) {
  return $e(e) ? "indeterminate" : e ? "checked" : "unchecked";
}
function zn(e) {
  const n = document.activeElement;
  for (const t of e)
    if (t === n || (t.focus(), document.activeElement !== n)) return;
}
function jn(e, n) {
  return e.map(
    (t, o) => e[(n + o) % e.length]
  );
}
function Zn(e, n, t) {
  const c = n.length > 1 && Array.from(n).every(
    (p) => p === n[0]
  ) ? n[0] : n, r = t ? e.indexOf(t) : -1;
  let i = jn(e, Math.max(r, 0));
  c.length === 1 && (i = i.filter(
    (p) => p !== t
  ));
  const f = i.find(
    (p) => p.toLowerCase().startsWith(c.toLowerCase())
  );
  return f !== t ? f : void 0;
}
function qn(e, n) {
  const { x: t, y: o } = e;
  let c = !1;
  for (let r = 0, i = n.length - 1; r < n.length; i = r++) {
    const s = n[r].x, f = n[r].y, p = n[i].x, d = n[i].y;
    f > o != d > o && t < (p - s) * (o - f) / (d - f) + s && (c = !c);
  }
  return c;
}
function Jn(e, n) {
  if (!n) return !1;
  const t = {
    x: e.clientX,
    y: e.clientY
  };
  return qn(t, n);
}
function ce(e) {
  return (n) => n.pointerType === "mouse" ? e(n) : void 0;
}
const mo = Cn, bo = Je, ho = yn, go = On, xo = tt, _o = Sn, wo = Ee, Po = In, Co = Ln, Eo = Nn, yo = Un, Mo = Gn, Oo = Wn, To = Hn, Do = Vn, So = Xn;
export {
  vo as $,
  ho as a,
  go as b,
  _o as c,
  wo as d,
  Po as e,
  Eo as f,
  yo as g,
  Mo as h,
  Do as i,
  So as j,
  mo as k,
  bo as l,
  xo as m,
  Co as n,
  To as o,
  Oo as p
};
