import * as c from "react";
import { useEffect as te, useState as re } from "react";
import { d as E, e as Z, b as ne } from "./tslib.es6-_U0NnHdK.js";
let D = 0;
function Ge() {
  te(() => {
    var e, t;
    const r = document.querySelectorAll("[data-radix-focus-guard]");
    return document.body.insertAdjacentElement("afterbegin", (e = r[0]) !== null && e !== void 0 ? e : G()), document.body.insertAdjacentElement("beforeend", (t = r[1]) !== null && t !== void 0 ? t : G()), D++, () => {
      D === 1 && document.querySelectorAll("[data-radix-focus-guard]").forEach(
        (n) => n.remove()
      ), D--;
    };
  }, []);
}
function G() {
  const e = document.createElement("span");
  return e.setAttribute("data-radix-focus-guard", ""), e.tabIndex = 0, e.style.cssText = "outline: none; opacity: 0; position: fixed; pointer-events: none", e;
}
var ae = function(e) {
  if (typeof document > "u")
    return null;
  var t = Array.isArray(e) ? e[0] : e;
  return t.ownerDocument.body;
}, R = /* @__PURE__ */ new WeakMap(), M = /* @__PURE__ */ new WeakMap(), T = {}, X = 0, q = function(e) {
  return e && (e.host || q(e.parentNode));
}, oe = function(e, t) {
  return t.map(function(r) {
    if (e.contains(r))
      return r;
    var n = q(r);
    return n && e.contains(n) ? n : (console.error("aria-hidden", r, "in not contained inside", e, ". Doing nothing"), null);
  }).filter(function(r) {
    return !!r;
  });
}, ce = function(e, t, r, n) {
  var o = oe(t, Array.isArray(e) ? e : [e]);
  T[r] || (T[r] = /* @__PURE__ */ new WeakMap());
  var u = T[r], l = [], i = /* @__PURE__ */ new Set(), g = new Set(o), h = function(s) {
    !s || i.has(s) || (i.add(s), h(s.parentNode));
  };
  o.forEach(h);
  var v = function(s) {
    !s || g.has(s) || Array.prototype.forEach.call(s.children, function(d) {
      if (i.has(d))
        v(d);
      else {
        var y = d.getAttribute(n), p = y !== null && y !== "false", a = (R.get(d) || 0) + 1, f = (u.get(d) || 0) + 1;
        R.set(d, a), u.set(d, f), l.push(d), a === 1 && p && M.set(d, !0), f === 1 && d.setAttribute(r, "true"), p || d.setAttribute(n, "true");
      }
    });
  };
  return v(t), i.clear(), X++, function() {
    l.forEach(function(s) {
      var d = R.get(s) - 1, y = u.get(s) - 1;
      R.set(s, d), u.set(s, y), d || (M.has(s) || s.removeAttribute(n), M.delete(s)), y || s.removeAttribute(r);
    }), X--, X || (R = /* @__PURE__ */ new WeakMap(), R = /* @__PURE__ */ new WeakMap(), M = /* @__PURE__ */ new WeakMap(), T = {});
  };
}, je = function(e, t, r) {
  r === void 0 && (r = "data-aria-hidden");
  var n = Array.from(Array.isArray(e) ? e : [e]), o = ae(e);
  return o ? (n.push.apply(n, Array.from(o.querySelectorAll("[aria-live]"))), ce(n, o, r, "aria-hidden")) : function() {
    return null;
  };
}, L = "right-scroll-bar-position", N = "width-before-scroll-bar", ie = "with-scroll-bars-hidden", ue = "--removed-body-scroll-bar-size";
function le(e, t) {
  return typeof e == "function" ? e(t) : e && (e.current = t), e;
}
function fe(e, t) {
  var r = re(function() {
    return {
      // value
      value: e,
      // last callback
      callback: t,
      // "memoized" public interface
      facade: {
        get current() {
          return r.value;
        },
        set current(n) {
          var o = r.value;
          o !== n && (r.value = n, r.callback(n, o));
        }
      }
    };
  })[0];
  return r.callback = t, r.facade;
}
function se(e, t) {
  return fe(null, function(r) {
    return e.forEach(function(n) {
      return le(n, r);
    });
  });
}
function de(e) {
  return e;
}
function ve(e, t) {
  t === void 0 && (t = de);
  var r = [], n = !1, o = {
    read: function() {
      if (n)
        throw new Error("Sidecar: could not `read` from an `assigned` medium. `read` could be used only with `useMedium`.");
      return r.length ? r[r.length - 1] : e;
    },
    useMedium: function(u) {
      var l = t(u, n);
      return r.push(l), function() {
        r = r.filter(function(i) {
          return i !== l;
        });
      };
    },
    assignSyncMedium: function(u) {
      for (n = !0; r.length; ) {
        var l = r;
        r = [], l.forEach(u);
      }
      r = {
        push: function(i) {
          return u(i);
        },
        filter: function() {
          return r;
        }
      };
    },
    assignMedium: function(u) {
      n = !0;
      var l = [];
      if (r.length) {
        var i = r;
        r = [], i.forEach(u), l = r;
      }
      var g = function() {
        var v = l;
        l = [], v.forEach(u);
      }, h = function() {
        return Promise.resolve().then(g);
      };
      h(), r = {
        push: function(v) {
          l.push(v), h();
        },
        filter: function(v) {
          return l = l.filter(v), r;
        }
      };
    }
  };
  return o;
}
function he(e) {
  e === void 0 && (e = {});
  var t = ve(null);
  return t.options = E({ async: !0, ssr: !1 }, e), t;
}
var O = function(e) {
  var t = e.sideCar, r = Z(e, ["sideCar"]);
  if (!t)
    throw new Error("Sidecar: please provide `sideCar` property to import the right car");
  var n = t.read();
  if (!n)
    throw new Error("Sidecar medium not found");
  return c.createElement(n, E({}, r));
};
O.isSideCarExport = !0;
function me(e, t) {
  return e.useMedium(t), O;
}
var Q = he(), Y = function() {
}, I = c.forwardRef(function(e, t) {
  var r = c.useRef(null), n = c.useState({
    onScrollCapture: Y,
    onWheelCapture: Y,
    onTouchMoveCapture: Y
  }), o = n[0], u = n[1], l = e.forwardProps, i = e.children, g = e.className, h = e.removeScrollBar, v = e.enabled, s = e.shards, d = e.sideCar, y = e.noIsolation, p = e.inert, a = e.allowPinchZoom, f = e.as, b = f === void 0 ? "div" : f, w = Z(e, ["forwardProps", "children", "className", "removeScrollBar", "enabled", "shards", "sideCar", "noIsolation", "inert", "allowPinchZoom", "as"]), S = d, C = se([r, t]), m = E(E({}, w), o);
  return c.createElement(
    c.Fragment,
    null,
    v && c.createElement(S, { sideCar: Q, removeScrollBar: h, shards: s, noIsolation: y, inert: p, setCallbacks: u, allowPinchZoom: !!a, lockRef: r }),
    l ? c.cloneElement(c.Children.only(i), E(E({}, m), { ref: C })) : c.createElement(b, E({}, m, { className: g, ref: C }), i)
  );
});
I.defaultProps = {
  enabled: !0,
  removeScrollBar: !0,
  inert: !1
};
I.classNames = {
  fullWidth: N,
  zeroRight: L
};
var ge = function() {
  if (typeof __webpack_nonce__ < "u")
    return __webpack_nonce__;
};
function ye() {
  if (!document)
    return null;
  var e = document.createElement("style");
  e.type = "text/css";
  var t = ge();
  return t && e.setAttribute("nonce", t), e;
}
function be(e, t) {
  e.styleSheet ? e.styleSheet.cssText = t : e.appendChild(document.createTextNode(t));
}
function Se(e) {
  var t = document.head || document.getElementsByTagName("head")[0];
  t.appendChild(e);
}
var pe = function() {
  var e = 0, t = null;
  return {
    add: function(r) {
      e == 0 && (t = ye()) && (be(t, r), Se(t)), e++;
    },
    remove: function() {
      e--, !e && t && (t.parentNode && t.parentNode.removeChild(t), t = null);
    }
  };
}, we = function() {
  var e = pe();
  return function(t, r) {
    c.useEffect(function() {
      return e.add(t), function() {
        e.remove();
      };
    }, [t && r]);
  };
}, K = function() {
  var e = we(), t = function(r) {
    var n = r.styles, o = r.dynamic;
    return e(n, o), null;
  };
  return t;
}, Ce = {
  left: 0,
  top: 0,
  right: 0,
  gap: 0
}, _ = function(e) {
  return parseInt(e || "", 10) || 0;
}, Ee = function(e) {
  var t = window.getComputedStyle(document.body), r = t[e === "padding" ? "paddingLeft" : "marginLeft"], n = t[e === "padding" ? "paddingTop" : "marginTop"], o = t[e === "padding" ? "paddingRight" : "marginRight"];
  return [_(r), _(n), _(o)];
}, Re = function(e) {
  if (e === void 0 && (e = "margin"), typeof window > "u")
    return Ce;
  var t = Ee(e), r = document.documentElement.clientWidth, n = window.innerWidth;
  return {
    left: t[0],
    top: t[1],
    right: t[2],
    gap: Math.max(0, n - r + t[2] - t[0])
  };
}, ke = K(), Ae = function(e, t, r, n) {
  var o = e.left, u = e.top, l = e.right, i = e.gap;
  return r === void 0 && (r = "margin"), `
  .`.concat(ie, ` {
   overflow: hidden `).concat(n, `;
   padding-right: `).concat(i, "px ").concat(n, `;
  }
  body {
    overflow: hidden `).concat(n, `;
    overscroll-behavior: contain;
    `).concat([
    t && "position: relative ".concat(n, ";"),
    r === "margin" && `
    padding-left: `.concat(o, `px;
    padding-top: `).concat(u, `px;
    padding-right: `).concat(l, `px;
    margin-left:0;
    margin-top:0;
    margin-right: `).concat(i, "px ").concat(n, `;
    `),
    r === "padding" && "padding-right: ".concat(i, "px ").concat(n, ";")
  ].filter(Boolean).join(""), `
  }
  
  .`).concat(L, ` {
    right: `).concat(i, "px ").concat(n, `;
  }
  
  .`).concat(N, ` {
    margin-right: `).concat(i, "px ").concat(n, `;
  }
  
  .`).concat(L, " .").concat(L, ` {
    right: 0 `).concat(n, `;
  }
  
  .`).concat(N, " .").concat(N, ` {
    margin-right: 0 `).concat(n, `;
  }
  
  body {
    `).concat(ue, ": ").concat(i, `px;
  }
`);
}, xe = function(e) {
  var t = e.noRelative, r = e.noImportant, n = e.gapMode, o = n === void 0 ? "margin" : n, u = c.useMemo(function() {
    return Re(o);
  }, [o]);
  return c.createElement(ke, { styles: Ae(u, !t, o, r ? "" : "!important") });
}, H = !1;
if (typeof window < "u")
  try {
    var W = Object.defineProperty({}, "passive", {
      get: function() {
        return H = !0, !0;
      }
    });
    window.addEventListener("test", W, W), window.removeEventListener("test", W, W);
  } catch {
    H = !1;
  }
var k = H ? { passive: !1 } : !1, Be = function(e) {
  return e.tagName === "TEXTAREA";
}, J = function(e, t) {
  var r = window.getComputedStyle(e);
  return (
    // not-not-scrollable
    r[t] !== "hidden" && // contains scroll inside self
    !(r.overflowY === r.overflowX && !Be(e) && r[t] === "visible")
  );
}, Me = function(e) {
  return J(e, "overflowY");
}, Te = function(e) {
  return J(e, "overflowX");
}, j = function(e, t) {
  var r = t;
  do {
    typeof ShadowRoot < "u" && r instanceof ShadowRoot && (r = r.host);
    var n = U(e, r);
    if (n) {
      var o = ee(e, r), u = o[1], l = o[2];
      if (u > l)
        return !0;
    }
    r = r.parentNode;
  } while (r && r !== document.body);
  return !1;
}, We = function(e) {
  var t = e.scrollTop, r = e.scrollHeight, n = e.clientHeight;
  return [
    t,
    r,
    n
  ];
}, Pe = function(e) {
  var t = e.scrollLeft, r = e.scrollWidth, n = e.clientWidth;
  return [
    t,
    r,
    n
  ];
}, U = function(e, t) {
  return e === "v" ? Me(t) : Te(t);
}, ee = function(e, t) {
  return e === "v" ? We(t) : Pe(t);
}, Le = function(e, t) {
  return e === "h" && t === "rtl" ? -1 : 1;
}, Ne = function(e, t, r, n, o) {
  var u = Le(e, window.getComputedStyle(t).direction), l = u * n, i = r.target, g = t.contains(i), h = !1, v = l > 0, s = 0, d = 0;
  do {
    var y = ee(e, i), p = y[0], a = y[1], f = y[2], b = a - f - u * p;
    (p || b) && U(e, i) && (s += b, d += p), i = i.parentNode;
  } while (
    // portaled content
    !g && i !== document.body || // self content
    g && (t.contains(i) || t === i)
  );
  return (v && (s === 0 || !o) || !v && (d === 0 || !o)) && (h = !0), h;
}, P = function(e) {
  return "changedTouches" in e ? [e.changedTouches[0].clientX, e.changedTouches[0].clientY] : [0, 0];
}, z = function(e) {
  return [e.deltaX, e.deltaY];
}, F = function(e) {
  return e && "current" in e ? e.current : e;
}, Ie = function(e, t) {
  return e[0] === t[0] && e[1] === t[1];
}, $e = function(e) {
  return `
  .block-interactivity-`.concat(e, ` {pointer-events: none;}
  .allow-interactivity-`).concat(e, ` {pointer-events: all;}
`);
}, De = 0, A = [];
function Xe(e) {
  var t = c.useRef([]), r = c.useRef([0, 0]), n = c.useRef(), o = c.useState(De++)[0], u = c.useState(function() {
    return K();
  })[0], l = c.useRef(e);
  c.useEffect(function() {
    l.current = e;
  }, [e]), c.useEffect(function() {
    if (e.inert) {
      document.body.classList.add("block-interactivity-".concat(o));
      var a = ne([e.lockRef.current], (e.shards || []).map(F), !0).filter(Boolean);
      return a.forEach(function(f) {
        return f.classList.add("allow-interactivity-".concat(o));
      }), function() {
        document.body.classList.remove("block-interactivity-".concat(o)), a.forEach(function(f) {
          return f.classList.remove("allow-interactivity-".concat(o));
        });
      };
    }
  }, [e.inert, e.lockRef.current, e.shards]);
  var i = c.useCallback(function(a, f) {
    if ("touches" in a && a.touches.length === 2)
      return !l.current.allowPinchZoom;
    var b = P(a), w = r.current, S = "deltaX" in a ? a.deltaX : w[0] - b[0], C = "deltaY" in a ? a.deltaY : w[1] - b[1], m, $ = a.target, x = Math.abs(S) > Math.abs(C) ? "h" : "v";
    if ("touches" in a && x === "h" && $.type === "range")
      return !1;
    var B = j(x, $);
    if (!B)
      return !0;
    if (B ? m = x : (m = x === "v" ? "h" : "v", B = j(x, $)), !B)
      return !1;
    if (!n.current && "changedTouches" in a && (S || C) && (n.current = m), !m)
      return !0;
    var V = n.current || m;
    return Ne(V, f, a, V === "h" ? S : C, !0);
  }, []), g = c.useCallback(function(a) {
    var f = a;
    if (!(!A.length || A[A.length - 1] !== u)) {
      var b = "deltaY" in f ? z(f) : P(f), w = t.current.filter(function(m) {
        return m.name === f.type && m.target === f.target && Ie(m.delta, b);
      })[0];
      if (w && w.should) {
        f.cancelable && f.preventDefault();
        return;
      }
      if (!w) {
        var S = (l.current.shards || []).map(F).filter(Boolean).filter(function(m) {
          return m.contains(f.target);
        }), C = S.length > 0 ? i(f, S[0]) : !l.current.noIsolation;
        C && f.cancelable && f.preventDefault();
      }
    }
  }, []), h = c.useCallback(function(a, f, b, w) {
    var S = { name: a, delta: f, target: b, should: w };
    t.current.push(S), setTimeout(function() {
      t.current = t.current.filter(function(C) {
        return C !== S;
      });
    }, 1);
  }, []), v = c.useCallback(function(a) {
    r.current = P(a), n.current = void 0;
  }, []), s = c.useCallback(function(a) {
    h(a.type, z(a), a.target, i(a, e.lockRef.current));
  }, []), d = c.useCallback(function(a) {
    h(a.type, P(a), a.target, i(a, e.lockRef.current));
  }, []);
  c.useEffect(function() {
    return A.push(u), e.setCallbacks({
      onScrollCapture: s,
      onWheelCapture: s,
      onTouchMoveCapture: d
    }), document.addEventListener("wheel", g, k), document.addEventListener("touchmove", g, k), document.addEventListener("touchstart", v, k), function() {
      A = A.filter(function(a) {
        return a !== u;
      }), document.removeEventListener("wheel", g, k), document.removeEventListener("touchmove", g, k), document.removeEventListener("touchstart", v, k);
    };
  }, []);
  var y = e.removeScrollBar, p = e.inert;
  return c.createElement(
    c.Fragment,
    null,
    p ? c.createElement(u, { styles: $e(o) }) : null,
    y ? c.createElement(xe, { gapMode: "margin" }) : null
  );
}
const Ye = me(Q, Xe);
var _e = c.forwardRef(function(e, t) {
  return c.createElement(I, E({}, e, { ref: t, sideCar: Ye }));
});
_e.classNames = I.classNames;
export {
  Ge as $,
  _e as R,
  je as h
};
